import {mapActions, mapGetters, mapMutations} from 'vuex';

export const RELEASEME_MODULE_NAME = 'releaseme';

export const RELEASEME_MODAL_ACTIONS = {
    EMAIL_DOCUMENT: 'Email_documents'
}

const initState = {
    is_connected: false,
    releases: {
        contract_templates: [],
        applicable_medias: [],
        restrictions: [],
        terms: [],
        territories: [],
        tags: []
    },
    archives: [],
    imports: [],
    modal: null,
    selected_contract: null,

    // for update
    contract_template: null,
    isLoading:{index:true}
};

const ReleaseMEModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        RESET(state) {
            state.is_connected = false;
            state.releases = {
                contract_templates: []
            };
            state.archives = [];
            state.imports = [];
        },
        connected(state, flag) {
            state.is_connected = flag;
        },

        // RELEASE LIST
        index(state, {body: releases, params}) {
            state.releases = releases
            state.isLoading.index = false
        },

        // ARCHIVE RELEASE LIST
        index_archive(state, {body: releases, params}) {
            state.archives = releases
        },

        // IMPORT RELEASE LIST
        index_importable(state, {body: releases, params}) {
            state.imports = releases
        },

        show(state, {body: data}) {
            state.contract_template = data;
        },

        archive_releases(state, release) {
            const index = state.releases.contract_templates.findIndex(r => r.contract_template_hashid === release.contract_template_hashid);
            if (index > -1) {
                state.releases.contract_templates.splice(index, 1);
                state.archives.push(release)
            }
        },

        destroy(state, {body: releases, params}) {
            const index = state.releases.contract_templates.findIndex(r => r.contract_template_hashid === params.contract_template_hashid);
            if (index > -1) {
                state.releases.contract_templates.splice(index, 1);
            }
        },
        update_star(state, {body, params}) {
            let release = state.releases.contract_templates.find(r => r.contract_template_hashid === params.contract_template_hashid);
            if (release) {
                release.starred = params.starred
            }
        },
        modalData(state, data) {
            if (state.modal && state.modal.action === RELEASEME_MODAL_ACTIONS.EMAIL_DOCUMENT) {
                state.selected_contract = null;
            }
            state.modal = (typeof data === "string" ? {action: data} : data) || null;
        },

        emailDocument(state,template) {
            state.selected_contract = template
        },

        unarchive_releases(state, ids) {
            ids.forEach(id => {
                    const index = state.archives.findIndex(a => a.id === id);
                    if (index > -1) {
                        state.releases.contract_templates.push(state.archives[index]);
                        state.archives.splice(index, 1);
                        state.releases.contract_templates.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    }
                }
            )
        },
        setLoading(state,{action:action_name,flag=true}){
            state.isLoading[action_name] = flag
        }

    },
    actions: {
        // close - this.manageModal();
        // add - this.manageModal("add")
        // edit - this.manageModal("edit")
        // OR edit - this.manageModal({action: "edit", payload: "dad23dg34"})
        manageModal({commit}, data) {
            commit("modalData", data);
            $('#releaseme-modal').modal(data ? 'show' : 'hide');
        },
    },
    getters: {
        is_connected: s => s.is_connected,

        releases: s => s.releases,

        imports: s => s.imports,
        archives: s => s.archives,

        modal: s => s.modal,

        contract_template: s => s.contract_template,

        selected_contract: s => s.selected_contract,
        isLoading: s => s.isLoading,
    }
};

export const mapReleaseMEActions = data => mapActions(RELEASEME_MODULE_NAME, data);
export const mapReleaseMEGetters = data => mapGetters(RELEASEME_MODULE_NAME, data);

export const mapReleaseMEMutations = data => mapMutations(RELEASEME_MODULE_NAME, data);

export default ReleaseMEModule