import Vue from "vue";
import moment from "moment";
import data from "./suite-app.data.json";
import {PRICING_PLANS} from "./Admin/View/store/admin.vuex";

Vue.filter("formatDashboardDate", (value) => {
    if (value) {
        const date = moment(value);
        const formattedDate = date.format("MMMM Do");
        const formattedTime = date.format("h:mm A");
        return `${formattedDate} - ${formattedTime}`;
    }
});

Vue.filter('clientName', function (value) {
    const client = data.project.clients.find(client => (value || '').toLowerCase() === client.identifier);
    return client ? client.name : value;
});

Vue.filter('enumToStr', function (value) {
    return value.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
});

Vue.filter('roleToStr', function (value) {
    switch (value) {
        case "project_admin":
            return "Project Owner";
        case "project_manager":
            return "Project Manager";
        case "associate":
            return "Associate";
    }
    return "";
});

Vue.filter("fromNow", (value) => {
    if (value) {
        return moment(value).fromNow();
    }
});

Vue.filter("timeLeft", (value) => {
    if (value) {
        return moment.duration(moment(value).diff(moment())).humanize();
    }
});

Vue.filter("formatDate", (value) => {
    if (value) {
        return moment(String(value)).format("MMMM D, YYYY");
    }
});

Vue.filter("formatDateTime", (value) => {
    if (value) {
        return moment(String(value)).format("MMMM D, YYYY hh:MM A");
    }
});

Vue.filter("capitalizeFirstWord", (value) => {
    if (value) {
        if (!value) return "";
        value = value.toString();
        if (value.trim() === "") return "";
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
});

Vue.filter("formatDateAndTime", (value) => {
    if (value) {
        return moment(String(value)).format("MMMM D, YYYY, h:mm:ss A");
    }
});

Vue.filter("formatDuration", (value) => {
    if (value) {
        const secondsInMinute = 60;
        const secondsInHour = secondsInMinute * 60;
        const secondsInDay = secondsInHour * 24;

        const days = Math.floor(value / secondsInDay);
        const hours = Math.floor((value % secondsInDay) / secondsInHour);
        const minutes = Math.floor((value % secondsInHour) / secondsInMinute);
        const seconds = Math.floor(value % secondsInMinute);

        return `${days.toString().padStart(2, "0")}:${hours.toString().padStart(2, "0")}`
            + `:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
        return "00:00:00:00"
    }
});

Vue.filter("dateFormat", (value) => {
    if (value) {
        return moment(value).format("DD/MM/YYYY");
    }
});

Vue.filter('bytesToSize', function (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
});

Vue.filter('currencySymbol', function (currencyCode) {
    currencyCode = currencyCode.toUpperCase();
    // Define a mapping of currency codes to symbols
    const currencySymbols = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        // Add more currency codes and symbols as needed
    };

    // Return the symbol corresponding to the currency code
    return currencySymbols[currencyCode] || currencyCode;
});

Vue.filter("planType", function (value) {
    return (PRICING_PLANS.find(p => p.key === value) || {}).title;
});

Vue.filter('truncate', function (value, size) {
    if (value) {
        if (value.length <= size) {
            return value;
        } else {
            return value.substring(0, size) + '...';
        }
    }
    return null;
});

Vue.filter('file_extension', function (value) {
    if (!value) return '';

    const parts = value.split('.');
    if (parts.length === 1) return '';

    return parts.pop().toUpperCase();
});
