import { RouteConfig } from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "releasemes" <<
 * where ever we mention this, it will group all components in one group.
 */
const ReleaseMERoutes = [
  {
    path: "signme",
    component: () => import(/* webpackChunkName: 'releasemes' */ "./ReleaseME.vue"),
    children: [
      {
        path: "",
        name: "releasemes.index",
        component: () => import(/* webpackChunkName: 'releasemes' */ "./pages/ReleaseMEIndexV2.vue"),
        meta: {
          topbar: true,
          project_title: true,
          sub_sidebar: "SignMeSubSidebar",
        },
      },
      {
        path: "new",
        name: "releasemes.new",
        meta: { project_title: true, topbar: true },
        component: () => import(/* webpackChunkName: 'releasemes' */ "./pages/ContractBuilderv2.vue"),
      },
      {
        path: ":contract_template_hashid/edit",
        name: "releasemes.edit",
        component: () => import(/* webpackChunkName: 'releasemes' */ "./pages/ContractBuilderEditv2.vue"),
      },
      {
        path: "import",
        name: "releasemes.import",
        component: () => import(/* webpackChunkName: 'releasemes' */ "./pages/ReleaseMEImport.vue"),
      },
    ],
  },
];

export const SignMeSignUpRoutes = [
  {
    path: "sign-me",
    component: () => import("../ReleaseME/pages/sign-up/SignMeSignUpView.vue"),
    meta: { sidebar: false },
    children: [
      {
        path: "",
        name: "auth.signup.signme",
        component: () => import("../Auth/pages/AuthModuleSignUp.vue"),
        meta: { sidebar: false, topbar: "TopbarSignup" },
      },
    ],
  },
];

export default ReleaseMERoutes;
