import { RouteConfig } from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "auth" <<
 * where ever we mention this, it will group all components in one group.
 */
const AuthRoutes = [
  {
    path: "/auth",
    name: "auth",
    redirect: { name: "auth.login" },
    component: () => import(/* webpackChunkName: "auth" */ "./Auth.vue"),
    children: [
      {
        name: "auth.login",
        path: "/login",
        component: () =>
          import(/* webpackChunkName: "auth" */ "./pages/AuthLogin.vue"),
        meta: { sidebar: "none" },
      },
      {
        name: "auth.logout",
        path: "/logout",
        component: () =>
          import(/* webpackChunkName: "auth" */ "./pages/AuthLogout.vue"),
        meta: { sidebar: "none" },
      },
      {
        name: "auth.setup-account",
        alias: "/setup-account/:hashid",
        path: "/auth/setup-account/:hashid",
        component: () =>
          import(/* webpackChunkName: "auth" */ "./pages/AuthSetupAccount.vue"),
        meta: { sidebar: "none" },
      },
      {
        path: "/signup",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "./pages/AuthSignupSwitcher.vue"
          ),
        name: "auth.signup",
        meta: { sidebar: "none" },
      },
      {
        path: "/signup/:token",
        name: "auth.signup.finish",
        component: () => import("./pages/AuthModuleSignUp.vue"),
        meta: { sidebar: "none" },
      },
      {
        name: "auth.forgot-password",
        alias: "/forgot-password",
        path: "/auth/forgot-password",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "./pages/AuthForgotPassword.vue"
          ),
        meta: { sidebar: "none" },
      },
      {
        name: "masquerade",
        path: "/auth/masquerade/:email",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "./pages/AuthMasqueradeUser.vue"
          ),
        meta: { sidebar: "none" },
      },
      {
        name: "auth.signup-product",
        path: "/:product/register",
        component: () =>
            import(
                /* webpackChunkName: "auth" */ "./pages/SignUpProduct.vue"
                ),
        meta: { sidebar: "none" },
      },
    ],
  },
];

export default AuthRoutes;
