<template>
  <div>
    <div v-if="showAlert && !current_user.is_email_verified" class="mt-3 alert alert-warning" role="alert">
      <div class="d-flex align-items-center">
              <span>
            Hi, Thank you for signing up, your email is not verified yet. To gain unrestricted access, please verify your email now.
              </span>
        <button class="ml-auto btn btn-small btn-primary" @click.prevent="openEmailVerificationModal()">Verify
          Email
        </button>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

import BasicProjectMixin from "../mixin/project.mixin";
import { PROJECT_MODAL_ACTIONS } from "../store/project.vuex";

export default {
  name: "ProjectShow",
  mixins: [BasicProjectMixin],
  data() {
    return {
      showAlert: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.showAlert = true;
    }, 1000); // Delay in milliseconds (3000ms = 3 seconds)
  },
  methods: {
    openEmailVerificationModal() {
      this.manageModal({
        action: PROJECT_MODAL_ACTIONS.EMAIL_VERIFICATION,
        payload: null,
      });
    }
  }
};
</script>

<style scoped>
</style>