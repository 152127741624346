import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import ProjectRoutes from "./Project/project.routes";
import ReleaseMERoutes from "./ReleaseME/releaseme.routes";
import DocumentsRoutes from "./Documents/documents.routes";
import DirectMERoutes from "./DirectME/directme.routes";
import DeliverMERoutes from "./DeliverME/deliverme.routes";
import TaskManagerRoutes from "./TaskManager/taskmanager.routes";
import PayMERoutes from "./PayME/payme.routes";
import ContactManagerRoutes from "./ContactManager/contactmanager.routes";
import ProfileSettingsRoutes from "./ProfileSettings/profilesettings.routes";
import AdminRoutes from "./Admin/admin.routes";
import DashboardRoutes from "./ProjectDashboard/projectdashboard.route";
import AuthRoutes from "./Auth/auth.routes";
import SignerRoutes from "./Signer/signer.routes";
import MediaMERoutes from "./MediaME/mediame.routes";
import CalendarRoutes from "./Calendar/calendar.routes";
import AccountRoutes from "./Project/account.routes";
import WorkspaceRoutes from "./Workspace/workspace.routes";

// createWebHistory
Vue.use(VueRouter);

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 */
const routes = [
    {
        path: "/",
        name: "home",
        // redirect: {name: "auth.login"}
    },
    ...AuthRoutes,
    ...SignerRoutes,
    ...AccountRoutes,
    ...ProjectRoutes,
    ...ProfileSettingsRoutes,
    ...AdminRoutes,
    {path: "/:catchAll(.*)", redirect: "/"}
];

// add workspace routes
routes.find(r => r.name === "accounts").children.push(...WorkspaceRoutes);

// project modules
let project = routes
    .find((r) => r.name === "projects")
    .children.find((r) => r.name === "projects.show");
project.children.push(
    ...DashboardRoutes,
    ...DocumentsRoutes,
    ...DirectMERoutes,
    ...ReleaseMERoutes,
    ...DeliverMERoutes,
    ...PayMERoutes,
    ...MediaMERoutes,
    ...TaskManagerRoutes,
    ...ContactManagerRoutes,
    ...CalendarRoutes
    // ...CallSheetRoutes
);

const router = new VueRouter({
    routes,
    mode: "hash",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                offset: {
                    x: 0,
                    y: 0,
                },
                behavior: "smooth",
            };
        }
    },
});

export default router;